import React, { useState, useEffect } from 'react';
import config from '../../components/Config.js';
import styled from 'styled-components';

const Section = styled.section`
  background: #2b2b2b;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const AdminContainer = styled.div`
  padding: 30px;
  background-color: #1a1a1a;
  color: #f0f0f0;
  font-family: 'Arial', sans-serif;
  max-width: 900px;
  margin: 40px auto;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #f48024;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 6px;
  border: 1px solid #333;
  background-color: #333;
  color: #f0f0f0;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: #f48024;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 6px;
  border: 1px solid #333;
  background-color: #333;
  color: #f0f0f0;
  font-size: 1rem;
  resize: vertical;
  outline: none;

  &:focus {
    border-color: #f48024;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  flex: 1;
  padding: 12px 20px;
  background-color: #f48024;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #e1701c;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const PreviewContainer = styled.div`
  margin-top: 30px;
  padding: 20px;
  background-color: #2b2b2b;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
`;

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #333;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
`;

const HeroText = styled.div`
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

const HeroImage = styled.div`
  width: 40%;
  height: 300px;
  background-color: #ddd;
  background-image: ${(props) => `url(${props.imageUrl || 'https://via.placeholder.com/300'})`};
  background-size: cover;
  background-position: center;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const TeamCards = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const TeamCard = styled.div`
  flex: 1;
  background: #444;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TeamInfo = styled.div`
  h3 {
    margin: 0;
    font-size: 1.5rem;
    color: #f48024;

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .role {
    font-size: 1rem;
    color: #ccc;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }

  p {
    font-size: 1rem;
    color: #f0f0f0;

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
`;

const TeamImage = styled.div`
  width: 100%;
  height: 150px;
  background-color: #ddd;
  background-image: ${(props) => `url(${props.imageUrl || 'https://via.placeholder.com/150'})`};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const AdminAboutPage = () => {
  const [heroTitle, setHeroTitle] = useState('');
  const [heroDescription, setHeroDescription] = useState('');
  const [heroImage, setHeroImage] = useState(null);
  const [heroImageUrl, setHeroImageUrl] = useState('');

  const [companyOverviewTitle, setCompanyOverviewTitle] = useState('');
  const [companyOverviewText, setCompanyOverviewText] = useState('');

  const [missionTitle, setMissionTitle] = useState('');
  const [missionText, setMissionText] = useState('');

  const [teamMembers, setTeamMembers] = useState([]);
  const [newMember, setNewMember] = useState({ name: '', role: '', bio: '', imageFile: null, imageUrl: '' });

  const [showPreview, setShowPreview] = useState({
    heroSection: false,
    companyOverview: false,
    mission: false,
    team: false,
  });

  useEffect(() => {
    // Fetch the existing about page data
    const fetchAboutPage = async () => {
      try {
        const response = await fetch(`${config.API_ENDPOINT}/admin/ui/about/about-page`);
        const data = await response.json();

        // Populate the state with the fetched data
        setHeroTitle(data.heroTitle || '');
        setHeroDescription(data.heroDescription || '');
        setHeroImageUrl(data.heroImage || ''); // Set the base64 URL for preview
        setCompanyOverviewTitle(data.companyOverviewTitle || '');
        setCompanyOverviewText(data.companyOverviewText || '');
        setMissionTitle(data.missionTitle || '');
        setMissionText(data.missionText || '');
        setTeamMembers(data.teamMembers || []);
      } catch (error) {
        console.error('Error fetching about page data:', error);
      }
    };

    fetchAboutPage();
  }, []);

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('heroTitle', heroTitle);
    formData.append('heroDescription', heroDescription);
  
    if (heroImage) {
      formData.append('heroImage', heroImage);
    }
  
    formData.append('companyOverviewTitle', companyOverviewTitle);
    formData.append('companyOverviewText', companyOverviewText);
    formData.append('missionTitle', missionTitle);
    formData.append('missionText', missionText);
  
    teamMembers.forEach((member, index) => {
      formData.append(`teamMemberImages`, member.imageFile); // Attach the image files
    });
  
    formData.append('teamMembers', JSON.stringify(teamMembers.map((member) => ({
      name: member.name,
      role: member.role,
      bio: member.bio,
      // Exclude imageFile here because the actual image is sent separately
    }))));
  
    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin/ui/about/about-page`, {
        method: 'POST',
        body: formData,
      });
  
      const result = await response.json();
      if (response.ok) {
        alert('Content updated successfully!');
      } else {
        console.error('Failed to update content:', result.error);
        alert('Failed to update content.');
      }
    } catch (error) {
      console.error('Error saving about page data:', error);
      alert('Error saving about page data.');
    }
  };
  

  const addTeamMember = () => {
    if (newMember.imageFile) {
      const previewUrl = URL.createObjectURL(newMember.imageFile);
      setTeamMembers([...teamMembers, { ...newMember, imageUrl: previewUrl }]);
    } else {
      setTeamMembers([...teamMembers, newMember]);
    }
    setNewMember({ name: '', role: '', bio: '', imageFile: null, imageUrl: '' });
  };

  return (
    <AdminContainer>
      <h2>Edit About Page</h2>

      {/* Hero Section */}
      <FormGroup>
        <Label htmlFor="heroTitle">Hero Title</Label>
        <Input
          type="text"
          id="heroTitle"
          value={heroTitle}
          onChange={(e) => setHeroTitle(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="heroDescription">Hero Description</Label>
        <TextArea
          id="heroDescription"
          rows="4"
          value={heroDescription}
          onChange={(e) => setHeroDescription(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="heroImage">Hero Background Image</Label>
        <Input
          type="file"
          id="heroImage"
          accept="image/*"
          onChange={(e) => {
            setHeroImage(e.target.files[0]);
            setHeroImageUrl(URL.createObjectURL(e.target.files[0]));
          }}
        />
      </FormGroup>

      <ButtonContainer>
        <Button onClick={() => setShowPreview({ ...showPreview, heroSection: !showPreview.heroSection })}>
          {showPreview.heroSection ? 'Hide Hero Preview' : 'Show Hero Preview'}
        </Button>
      </ButtonContainer>

      {showPreview.heroSection && (
        <PreviewContainer>
          <HeroSection>
            <HeroText>
              <h1>{heroTitle}</h1>
              <p>{heroDescription}</p>
            </HeroText>
            <HeroImage imageUrl={heroImageUrl} />
          </HeroSection>
        </PreviewContainer>
      )}

      {/* Company Overview Section */}
      <FormGroup>
        <Label htmlFor="companyOverviewTitle">Company Overview Title</Label>
        <Input
          type="text"
          id="companyOverviewTitle"
          value={companyOverviewTitle}
          onChange={(e) => setCompanyOverviewTitle(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="companyOverviewText">Company Overview Text</Label>
        <TextArea
          id="companyOverviewText"
          rows="4"
          value={companyOverviewText}
          onChange={(e) => setCompanyOverviewText(e.target.value)}
        />
      </FormGroup>

      <ButtonContainer>
        <Button onClick={() => setShowPreview({ ...showPreview, companyOverview: !showPreview.companyOverview })}>
          {showPreview.companyOverview ? 'Hide Company Overview Preview' : 'Show Company Overview Preview'}
        </Button>
      </ButtonContainer>

      {showPreview.companyOverview && (
        <PreviewContainer>
          <Section>
            <h2>{companyOverviewTitle}</h2>
            <p>{companyOverviewText}</p>
          </Section>
        </PreviewContainer>
      )}

      {/* Mission Section */}
      <FormGroup>
        <Label htmlFor="missionTitle">Mission Title</Label>
        <Input
          type="text"
          id="missionTitle"
          value={missionTitle}
          onChange={(e) => setMissionTitle(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="missionText">Mission Text</Label>
        <TextArea
          id="missionText"
          rows="4"
          value={missionText}
          onChange={(e) => setMissionText(e.target.value)}
        />
      </FormGroup>

      <ButtonContainer>
        <Button onClick={() => setShowPreview({ ...showPreview, mission: !showPreview.mission })}>
          {showPreview.mission ? 'Hide Mission Preview' : 'Show Mission Preview'}
        </Button>
      </ButtonContainer>

      {showPreview.mission && (
        <PreviewContainer>
          <Section>
            <h2>{missionTitle}</h2>
            <p>{missionText}</p>
          </Section>
        </PreviewContainer>
      )}

      {/* Team Members Section */}
      <h3>Manage Team Members</h3>
      <FormGroup>
        <Label htmlFor="memberName">Name</Label>
        <Input
          type="text"
          id="memberName"
          value={newMember.name}
          onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="memberRole">Role</Label>
        <Input
          type="text"
          id="memberRole"
          value={newMember.role}
          onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="memberBio">Bio</Label>
        <TextArea
          id="memberBio"
          rows="3"
          value={newMember.bio}
          onChange={(e) => setNewMember({ ...newMember, bio: e.target.value })}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="memberImage">Profile Image</Label>
        <Input
          type="file"
          id="memberImage"
          accept="image/*"
          onChange={(e) => setNewMember({ ...newMember, imageFile: e.target.files[0], imageUrl: URL.createObjectURL(e.target.files[0]) })}
        />
      </FormGroup>

      <ButtonContainer>
        <Button onClick={addTeamMember}>Add Team Member</Button>
        <Button onClick={() => setShowPreview({ ...showPreview, team: !showPreview.team })}>
          {showPreview.team ? 'Hide Team Preview' : 'Show Team Preview'}
        </Button>
        <Button onClick={handleSave}>Save All Changes</Button>
      </ButtonContainer>

      {showPreview.team && (
        <PreviewContainer>
          <Section>
            <h2>Meet the Team</h2>
            <TeamCards>
              {teamMembers.map((member, index) => (
                <TeamCard key={index}>
                  <TeamImage imageUrl={member.imageUrl} />
                  <TeamInfo>
                    <h3>{member.name || 'Unnamed'}</h3>
                    <p className="role">{member.role || 'No Role'}</p>
                    <p>{member.bio || 'No Bio Available'}</p>
                  </TeamInfo>
                </TeamCard>
              ))}
            </TeamCards>
          </Section>
        </PreviewContainer>
      )}
    </AdminContainer>
  );
};

export default AdminAboutPage;
