import React, { useEffect, useState } from 'react';
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import HeaderUser from './components/HeaderUser';

import AdminHome from './pages/admin/AdminHome.js';
import AdminCoaches from './pages/admin/AdminCoach.js';
import AdminAbout from './pages/admin/AdminAbout.js';
import AdminCreateGame from './pages/admin/AdminCreateGame.js';
import AdminGameList from './pages/admin/AdminGameList.js';
import CoachAdminDashboard from './pages/admin/CoachManagement/AdminDashboard.js';
import EditCoachProfile from './pages/admin/CoachManagement/EditCoachProfile';
import CourseList from './pages/admin/CourseManagement/CourseList';
import CourseForm from './pages/admin/CourseManagement/CourseForm';
import GuideManagement from './pages/admin/GuideManagement/GuideManagement.js';
import AdminUserList from './pages/admin/AdminUserManagement.js';

import AdminHeader from './components/AdminHeader'; // Import your admin-specific header component
import config from './components/Config.js';
import AdminEditGame from './pages/admin/AdminEditGame.js';


const Main = ({ loginState, loginStateUpdate }) => {
  const [checkCompleted, setCheckCompleted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkVerification = async () => {
      try {
        const response = await fetch(`${config.API_ENDPOINT}/auth/check-verification`, {
          method: 'GET',
          credentials: 'include',
        });

        const result = await response.json();

        if (result.isVerified) {
          loginStateUpdate(true);
        } else if (result.profileCompleted) {
          navigate('/verification'); // Navigate to the verification page if profile is completed but not verified
        }
      } catch (error) {
        console.error('Failed to check verification status:', error);
      } finally {
        setCheckCompleted(true); // Mark the check as completed
      }
    };

    if (!checkCompleted) {
      checkVerification();
    }
  }, [loginStateUpdate, navigate, checkCompleted]);

  if (!checkCompleted) {
    return <div>Loading...</div>; // Display a loading message or spinner while checking verification
  }

  // Check if the current route is under /admin or /coach
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isCoachRoute = location.pathname.startsWith('/coach-dashboard');

  return (
    <>
      {isAdminRoute ? (
        <AdminHeader /> // Render the AdminHeader component for admin routes
      ) : isCoachRoute ? (
        <div></div> // Render the coach-specific header for coach routes
      ) : loginState === false ? (
        <Header /> // Render the standard header for non-authenticated users
      ) : (
        <HeaderUser loginStateUpdate={loginStateUpdate} /> // Render the user header for authenticated users
      )}
      <Routes>

        <Route path="/admin/admin-home" element={ <AdminHome /> } />
        <Route path="/admin/admin-coaches" element={ <AdminCoaches /> } />
        <Route path="/admin/admin-about" element={ <AdminAbout /> } />
        <Route path="/admin/create-game" element={ <AdminCreateGame /> } />
        <Route path="/admin/manage-games" element={ <AdminGameList /> } />
        <Route path="/admin/edit-game/:id" element={<AdminEditGame/>} />
        <Route path="/admin/manage-coaches" element={<CoachAdminDashboard/>} />
        <Route path="/admin/coach/edit/:id" element={<EditCoachProfile />} />
        <Route path="/admin/courses" element={<CourseList />} />
        <Route path="/admin/courses/new" element={<CourseForm isEdit={false} />} />
        <Route path="/admin/courses/edit/:id" element={<CourseForm isEdit={true} />} /> 
        <Route path="/admin/guides" element={<GuideManagement />} />
        <Route path="/admin/user-management" element={< AdminUserList /> } />      
        
      </Routes>
    </>
  );
};

export default Main;
