import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../components/Config.js';

const AdminEditGame = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [gameData, setGameData] = useState({
    name: '',
    description: '',
    publisher: '', // Added publisher field
    genre: '',
    platform: [],
    imageUrl: '',
    releaseDate: '',
  });

  const platformOptions = ['PC', 'Console', 'Mobile'];
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await fetch(`${config.API_ENDPOINT}/admin/games/${id}`);
        const data = await response.json();
        setGameData(data);
      } catch (error) {
        setStatusMessage('Error fetching game details.');
      }
    };

    fetchGameDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGameData({
      ...gameData,
      [name]: value,
    });
  };

  const handlePlatformSelection = (platform) => {
    setGameData((prevData) => ({
      ...prevData,
      platform: prevData.platform.includes(platform)
        ? prevData.platform.filter((p) => p !== platform)
        : [...prevData.platform, platform],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin/games/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(gameData),
      });

      if (response.ok) {
        setStatusMessage('Game updated successfully!');
        navigate('/admin/manage-games'); // Redirect back to the game list page
      } else {
        setStatusMessage('Error updating the game. Please try again.');
      }
    } catch (error) {
      setStatusMessage('Error updating the game. Please try again.');
    }
  };

  return (
    <AdminEditGameContainer>
      <h2>Edit Game</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={gameData.name}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <label>Description:</label>
          <textarea
            name="description"
            value={gameData.description}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <label>Publisher:</label> {/* Added Publisher field */}
          <input
            type="text"
            name="publisher"
            value={gameData.publisher}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <label>Genre:</label>
          <input
            type="text"
            name="genre"
            value={gameData.genre}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <label>Platform:</label>
          <PlatformOptions>
            {platformOptions.map((platform) => (
              <PlatformOption
                key={platform}
                isSelected={gameData.platform.includes(platform)}
                onClick={() => handlePlatformSelection(platform)}
              >
                {platform}
              </PlatformOption>
            ))}
          </PlatformOptions>
        </FormGroup>
        <FormGroup>
          <label>Image URL:</label>
          <input
            type="text"
            name="imageUrl"
            value={gameData.imageUrl}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <label>Release Date:</label>
          <input
            type="date"
            name="releaseDate"
            value={gameData.releaseDate ? gameData.releaseDate.split('T')[0] : ''}
            onChange={handleChange}
          />
        </FormGroup>
        <SubmitButton type="submit">Save Changes</SubmitButton>
      </Form>
      {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
    </AdminEditGameContainer>
  );
};

// Styled components

const AdminEditGameContainer = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    background-color: #333;
    color: #ffffff;
  }
`;

const PlatformOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const PlatformOption = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${(props) => (props.isSelected ? '#ff4500' : '#333')};
  color: ${(props) => (props.isSelected ? '#fff' : '#ccc')};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) =>
      props.isSelected ? '#ff2f00' : '#444'};
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #f48024;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e1701c;
  }
`;

const StatusMessage = styled.div`
  margin-top: 20px;
  color: #f48024;
  font-size: 16px;
`;

export default AdminEditGame;
