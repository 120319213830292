import React, { useState, useEffect } from 'react';
import CoachList from './CoachList';
import styled from 'styled-components';
import config from '../../../components/Config';

const AdminDashboardContainer = styled.div`
  padding: 20px;
  background-color: #1a1a1a; /* ProAcademy.gg theme background */
  min-height: 100vh;
  color: #fff; /* Ensure text is visible on the dark background */
`;

const AdminDashboardTitle = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #fff; /* Title text color to match the theme */
`;

const AdminDashboard = () => {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch coaches data from the backend
    const fetchCoaches = async () => {
      try {
        const response = await fetch(`${config.API_ENDPOINT}/admin/coach/admin/coaches`); // Update with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch coaches');
        }
        const data = await response.json();
        setCoaches(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCoaches();
  }, []);

  return (
    <AdminDashboardContainer>
      <AdminDashboardTitle>Admin Dashboard: Manage Coaches</AdminDashboardTitle>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && <CoachList coaches={coaches} setCoaches={setCoaches} />}
    </AdminDashboardContainer>
  );
};

export default AdminDashboard;

