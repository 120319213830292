import React, { useState } from 'react';
import CoachCard from './CoachCard';
import styled from 'styled-components';

const CoachListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 300px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
`;

const CoachList = ({ coaches, setCoaches }) => {
  const [searchQuery, setSearchQuery] = useState('');

  // Filter coaches based on the search query
  const filteredCoaches = coaches.filter((coach) =>
    (coach?.user?.name || '').toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <SearchContainer>
        <SearchInput
          type="text"
          placeholder="Search for a coach..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchContainer>
      <CoachListContainer>
        {filteredCoaches.map((coach) => (
          <CoachCard key={coach._id} coach={coach} setCoaches={setCoaches} />
        ))}
      </CoachListContainer>
    </>
  );
};

export default CoachList;

