import React from 'react';
import Footer from './components/FooterNew';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex-grow: 1;
`;


const Layout = ({ children }) => {
  return (
    <PageWrapper>
      <Content>{children}</Content>
      <Footer />
    </PageWrapper>
  );
};

export default Layout;
