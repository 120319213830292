import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import config from '../../../components/Config'; // Adjust the path based on your project structure

// Define the missing components

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ToggleLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  color: #ffffff;
`;

const ToggleSwitch = styled.div`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 21px;
      width: 21px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;
    }
  }

  input:checked + span {
    background-color: #5cb85c;
  }

  input:checked + span::before {
    transform: translateX(24px);
  }
`;


// Styled components for the form and layout
const EditCoachContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 40px auto;
  color: #ffffff;
`;

const Card = styled.div`
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 100%;
  box-sizing: border-box;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
`;

const CardHeader = styled.h3`
  font-size: 22px;
  font-weight: bold;
  color: #5cb85c;
  margin-bottom: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: none;
  background-color: #333;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: none;
  background-color: #333;
  color: #ffffff;
  font-size: 16px;
  resize: none;
  box-sizing: border-box;
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: none;
  background-color: #333;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
`;

const TimeSlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TimeSlotBadge = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #444;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const RemoveButton = styled.button`
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #c0392b;
  }
`;

const FormButton = styled.button`
  padding: 12px 30px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    background-color: #4cae4c;
    transform: scale(1.05);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  position: sticky;
  bottom: 20px;
  max-width: 100%;
`;

const DateTimeInputWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const DatePickerWrapper = styled.div`
  flex: 1;
`;

const TimePickerWrapper = styled.div`
  flex: 1;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #5cb85c;
  margin: 20px 0;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: none;
  background-color: #333;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;

  // Customizing the calendar dropdown
  .react-datepicker__input-container input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background-color: #333;
    color: #ffffff;
    font-size: 16px;
    box-sizing: border-box;
  }

  .react-datepicker__header {
    background-color: #5cb85c;
  }

  .react-datepicker__current-month {
    color: #ffffff;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #333;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #4cae4c;
    color: #ffffff;
  }
`;

const EditCoachProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [coachName, setCoachName] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [bio, setBio] = useState('');
  const [availabilityCalendar, setAvailabilityCalendar] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [allGames, setAllGames] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [scheduleManagedBy, setScheduleManagedBy] = useState('coach');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch the current coach details, available games, and available courses
    const fetchCoachDetailsAndData = async () => {
      try {
        const [coachResponse, gamesResponse, coursesResponse] = await Promise.all([
          fetch(`${config.API_ENDPOINT}/admin/coach/admin/coach/${id}`),
          fetch(`${config.API_ENDPOINT}/admin/games`),
          fetch(`${config.API_ENDPOINT}/courses`),
        ]);

        if (coachResponse.ok && gamesResponse.ok && coursesResponse.ok) {
          const coachData = await coachResponse.json();
          const gamesData = await gamesResponse.json();
          const coursesData = await coursesResponse.json();

          setCoachName(coachData.coach.user.name);
          setProfileImage(coachData.coach.profileImage);
          setBio(coachData.coach.bio);
          setSelectedGames(coachData.coach.games.map((game) => game._id));
          setSelectedCourses(coachData.coach.courses.map((course) => course._id));
          setAllGames(gamesData);
          setAllCourses(coursesData);
          setScheduleManagedBy(coachData.coach.scheduleManagedBy);

          // Parse existing availability calendar data
          const parsedCalendar = coachData.coach.availabilityCalendar.map((slot) => ({
            date: new Date(slot.date),
            timeSlots: slot.timeSlots.join(', '),
          }));

          setAvailabilityCalendar(parsedCalendar);
          setLoading(false);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCoachDetailsAndData();
  }, [id]);

  const handleAddTimeSlot = () => {
    if (selectedDate && startTime && endTime) {
      const newSlot = {
        date: selectedDate,
        timeSlots: `${startTime} - ${endTime}`,
      };
      setAvailabilityCalendar([...availabilityCalendar, newSlot]);
      setSelectedDate(null);
      setStartTime('');
      setEndTime('');
    }
  };

  const handleRemoveSlot = (index) => {
    const updatedSlots = availabilityCalendar.filter((_, i) => i !== index);
    setAvailabilityCalendar(updatedSlots);
  };

  const handleGameToggle = (gameId) => {
    setSelectedGames((prevSelectedGames) =>
      prevSelectedGames.includes(gameId)
        ? prevSelectedGames.filter((id) => id !== gameId)
        : [...prevSelectedGames, gameId]
    );
  };

  const handleCourseToggle = (courseId) => {
    setSelectedCourses((prevSelectedCourses) =>
      prevSelectedCourses.includes(courseId)
        ? prevSelectedCourses.filter((id) => id !== courseId)
        : [...prevSelectedCourses, courseId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedCalendar = availabilityCalendar.map((slot) => ({
      date: slot.date,
      timeSlots: slot.timeSlots.split(',').map((timeSlot) => timeSlot.trim()),
    }));

    const updatedData = {
      profileImage,
      bio,
      games: selectedGames,
      courses: selectedCourses,
      availabilityCalendar: formattedCalendar,
      scheduleManagedBy,
    };

    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin/coach/admin/coach/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        alert('Coach profile updated successfully');
        navigate('/admin/manage-coaches');
      } else {
        throw new Error('Failed to update coach profile');
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  if (loading) return <EditCoachContainer>Loading...</EditCoachContainer>;
  if (error) return <EditCoachContainer>{error}</EditCoachContainer>;

  return (
    <EditCoachContainer>
      <Card>
        <CardHeader>Profile Information ({coachName})</CardHeader>
        <FormLabel>Profile Image URL</FormLabel>
        <FormInput
          type="text"
          value={profileImage}
          onChange={(e) => setProfileImage(e.target.value)}
        />
        <FormLabel>Bio</FormLabel>
        <FormTextarea
          rows="4"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
        <FormLabel>Schedule Managed By</FormLabel>
        <FormSelect
          value={scheduleManagedBy}
          onChange={(e) => setScheduleManagedBy(e.target.value)}
        >
          <option value="coach">Coach</option>
          <option value="admin">Admin</option>
        </FormSelect>
      </Card>

      <Card>
        <CardHeader>Select Games</CardHeader>
        <ToggleContainer>
          {allGames.map((game) => (
            <ToggleLabel key={game._id}>
              {game.name}
              <ToggleSwitch>
                <input
                  type="checkbox"
                  checked={selectedGames.includes(game._id)}
                  onChange={() => handleGameToggle(game._id)}
                />
                <span></span>
              </ToggleSwitch>
            </ToggleLabel>
          ))}
        </ToggleContainer>
      </Card>

      <Card>
        <CardHeader>Select Courses</CardHeader>
        <ToggleContainer>
          {allCourses.map((course) => (
            <ToggleLabel key={course._id}>
              {course.title}
              <ToggleSwitch>
                <input
                  type="checkbox"
                  checked={selectedCourses.includes(course._id)}
                  onChange={() => handleCourseToggle(course._id)}
                />
                <span></span>
              </ToggleSwitch>
            </ToggleLabel>
          ))}
        </ToggleContainer>
      </Card>

      <Card>
        <CardHeader>Availability Calendar</CardHeader>
        <Divider />
        <DateTimeInputWrapper>
          <DatePickerWrapper>
            <FormLabel>Select Date</FormLabel>
            <StyledDatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              className="date-picker"
            />
          </DatePickerWrapper>
          <TimePickerWrapper>
            <FormLabel>Start Time</FormLabel>
            <FormInput
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </TimePickerWrapper>
          <TimePickerWrapper>
            <FormLabel>End Time</FormLabel>
            <FormInput
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </TimePickerWrapper>
        </DateTimeInputWrapper>
        <FormButton type="button" onClick={handleAddTimeSlot}>
          Add Time Slot
        </FormButton>

        <div style={{
          height : "2rem"
        }}></div>

        <TimeSlotContainer>
          {availabilityCalendar.map((slot, index) => (
            <TimeSlotBadge key={index}>
              <span>
                {slot.date.toDateString()} - {slot.timeSlots}
              </span>
              <RemoveButton type="button" onClick={() => handleRemoveSlot(index)}>
                Remove
              </RemoveButton>
            </TimeSlotBadge>
          ))}
        </TimeSlotContainer>
      </Card>

      <ButtonContainer>
        <FormButton type="submit" onClick={handleSubmit}>
          Save Changes
        </FormButton>
        <FormButton type="button" onClick={() => navigate('/admin/manage-coaches')}>
          Cancel
        </FormButton>
      </ButtonContainer>
    </EditCoachContainer>
  );
};

export default EditCoachProfile;
