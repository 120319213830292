import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getCourseById, createCourse, updateCourse } from './CourseApi';
import { getGames } from './GameApi'; // Importing the Game API

// Styled Components
const CourseFormContainer = styled.div`
  padding: 20px;
  background-color: #1a1a1a;
  color: white;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const SectionHeader = styled.h2`
  font-size: 24px;
  color: #5cb85c;
  margin-bottom: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  padding: 0 10px; /* Adjust the padding for proper containment */
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  color: white;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  box-sizing: border-box; /* Ensures padding and border are included in width */
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  box-sizing: border-box; /* Ensures padding and border are included in width */
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  box-sizing: border-box; /* Ensures padding and border are included in width */
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ToggleLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c2c2c;
  padding: 10px;
  border-radius: 5px;
  color: white;
`;

const ToggleSwitch = styled.div`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 21px;
      width: 21px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;
    }
  }

  input:checked + span {
    background-color: #5cb85c;
  }

  input:checked + span::before {
    transform: translateX(24px);
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #4cae4c;
    transform: scale(1.05);
  }
`;

const CurriculumSection = styled.div`
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`;

const TaskSection = styled.div`
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ContentSection = styled.div`
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`;

const CourseForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({
    title: '',
    description: '',
    gameId: '',
    coaches: [],
    curriculum: [
      {
        category: '',
        tasks: [{ title: '', description: '' }]
      }
    ],
    registrationStartDate: '',
    registrationEndDate: '',
    startDate: '',
    endDate: '',
    image: '',
    content: [
      { type: 'live', link: '' },
      { type: 'recorded', link: '' }
    ],
  });
  const [games, setGames] = useState([]);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const gamesData = await getGames();
        setGames(gamesData);
      } catch (err) {
        console.error('Failed to fetch games');
      }
    };
  
    fetchGames();
  
    if (isEdit) {
      const fetchCourse = async () => {
        try {
          const course = await getCourseById(id);
          setCourseData({
            title: course.title,
            description: course.description,
            gameId: course.game ? course.game._id : '',
            coaches: course.coaches.length ? course.coaches.map(coach => coach._id) : [],
            curriculum: course.curriculum.length ? course.curriculum : [{ category: '', tasks: [{ title: '', description: '' }] }],
            registrationStartDate: course.registrationStartDate.split('T')[0],
            registrationEndDate: course.registrationEndDate.split('T')[0],
            startDate: course.startDate.split('T')[0],
            endDate: course.endDate.split('T')[0],
            image: course.image,
            content: course.content.length ? course.content : [{ type: 'live', link: '' }, { type: 'recorded', link: '' }],
          });
        } catch (err) {
          console.error('Failed to fetch course details');
        }
      };
      fetchCourse();
    } else {
      // Reset the form when switching to "new course" mode
      setCourseData({
        title: '',
        description: '',
        gameId: '',
        coaches: [],
        curriculum: [
          {
            category: '',
            tasks: [{ title: '', description: '' }]
          }
        ],
        registrationStartDate: '',
        registrationEndDate: '',
        startDate: '',
        endDate: '',
        image: '',
        content: [
          { type: 'live', link: '' },
          { type: 'recorded', link: '' }
        ],
      });
    }
  }, [id, isEdit]); // Ensure it runs when either `id` or `isEdit` changes

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleCurriculumChange = (index, field, value) => {
    const updatedCurriculum = [...courseData.curriculum];
    updatedCurriculum[index][field] = value;
    setCourseData({ ...courseData, curriculum: updatedCurriculum });
  };

  const handleTaskChange = (curriculumIndex, taskIndex, field, value) => {
    const updatedCurriculum = [...courseData.curriculum];
    updatedCurriculum[curriculumIndex].tasks[taskIndex][field] = value;
    setCourseData({ ...courseData, curriculum: updatedCurriculum });
  };

  const handleContentChange = (index, field, value) => {
    const updatedContent = [...courseData.content];
    updatedContent[index][field] = value;
    setCourseData({ ...courseData, content: updatedContent });
  };

  const handleGameToggle = (gameId) => {
    setCourseData((prevData) => ({
      ...prevData,
      gameId: prevData.gameId === gameId ? '' : gameId, // Toggle selection
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEdit) {
        await updateCourse(id, courseData);
      } else {
        await createCourse(courseData);
      }
      navigate('/admin/courses');
    } catch (err) {
      console.error('Failed to save course');
    }
  };

  return (
    <CourseFormContainer>
      <SectionHeader>{isEdit ? 'Edit Course' : 'Create New Course'}</SectionHeader>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <Label htmlFor="title">Course Title</Label>
          <Input
            type="text"
            name="title"
            value={courseData.title}
            onChange={handleInputChange}
            placeholder="Course Title"
            required
          />
        </InputContainer>

        <InputContainer>
          <Label htmlFor="description">Course Description</Label>
          <TextArea
            name="description"
            value={courseData.description}
            onChange={handleInputChange}
            placeholder="Course Description"
            required
          />
        </InputContainer>

        <InputContainer>
          <Label htmlFor="gameId">Game</Label>
          <ToggleContainer>
            {games.map((game) => (
              <ToggleLabel key={game._id}>
                {game.name}
                <ToggleSwitch>
                  <input
                    type="checkbox"
                    checked={courseData.gameId.toString() === game._id.toString()}                    
                    onChange={() => handleGameToggle(game._id)}
                  />
                  { console.log(courseData.gameId) }
                  { console.log(game._id)}
                  <span></span>
                </ToggleSwitch>
              </ToggleLabel>
            ))}
          </ToggleContainer>
        </InputContainer>

        <InputContainer>
          <Label htmlFor="registrationStartDate">Registration Start Date</Label>
          <Input
            type="date"
            name="registrationStartDate"
            value={courseData.registrationStartDate}
            onChange={handleInputChange}
            placeholder="Registration Start Date"
            required
          />
        </InputContainer>

        <InputContainer>
          <Label htmlFor="registrationEndDate">Registration End Date</Label>
          <Input
            type="date"
            name="registrationEndDate"
            value={courseData.registrationEndDate}
            onChange={handleInputChange}
            placeholder="Registration End Date"
            required
          />
        </InputContainer>

        <InputContainer>
          <Label htmlFor="startDate">Course Start Date</Label>
          <Input
            type="date"
            name="startDate"
            value={courseData.startDate}
            onChange={handleInputChange}
            placeholder="Course Start Date"
            required
          />
        </InputContainer>

        <InputContainer>
          <Label htmlFor="endDate">Course End Date</Label>
          <Input
            type="date"
            name="endDate"
            value={courseData.endDate}
            onChange={handleInputChange}
            placeholder="Course End Date"
            required
          />
        </InputContainer>

        <InputContainer>
          <Label htmlFor="image">Course Image URL</Label>
          <Input
            type="text"
            name="image"
            value={courseData.image}
            onChange={handleInputChange}
            placeholder="Course Image URL"
          />
        </InputContainer>

        <CurriculumSection>
          <h3>Curriculum</h3>
          {courseData.curriculum.map((curriculum, index) => (
            <div key={index}>
              <InputContainer>
                <Label htmlFor={`curriculum-category-${index}`}>Category</Label>
                <Input
                  id={`curriculum-category-${index}`}
                  type="text"
                  placeholder="Category"
                  value={curriculum.category}
                  onChange={(e) => handleCurriculumChange(index, 'category', e.target.value)}
                />
              </InputContainer>

              <TaskSection>
                <h4>Tasks</h4>
                {curriculum.tasks.map((task, taskIndex) => (
                  <div key={taskIndex}>
                    <InputContainer>
                      <Label htmlFor={`task-title-${index}-${taskIndex}`}>Task Title</Label>
                      <Input
                        id={`task-title-${index}-${taskIndex}`}
                        type="text"
                        placeholder="Task Title"
                        value={task.title}
                        onChange={(e) => handleTaskChange(index, taskIndex, 'title', e.target.value)}
                      />
                    </InputContainer>

                    <InputContainer>
                      <Label htmlFor={`task-description-${index}-${taskIndex}`}>Task Description</Label>
                      <TextArea
                        id={`task-description-${index}-${taskIndex}`}
                        placeholder="Task Description"
                        value={task.description}
                        onChange={(e) => handleTaskChange(index, taskIndex, 'description', e.target.value)}
                      />
                    </InputContainer>
                  </div>
                ))}
              </TaskSection>
            </div>
          ))}
        </CurriculumSection>

        <ContentSection>
          <h3>Content</h3>
          {courseData.content.map((content, index) => (
            <div key={index}>
              <InputContainer>
                <Label htmlFor={`content-type-${index}`}>Content Type</Label>
                <Select
                  id={`content-type-${index}`}
                  value={content.type}
                  onChange={(e) => handleContentChange(index, 'type', e.target.value)}
                >
                  <option value="live">Live</option>
                  <option value="recorded">Recorded</option>
                </Select>
              </InputContainer>

              <InputContainer>
                <Label htmlFor={`content-link-${index}`}>Content Link</Label>
                <Input
                  id={`content-link-${index}`}
                  type="text"
                  placeholder="Content Link"
                  value={content.link}
                  onChange={(e) => handleContentChange(index, 'link', e.target.value)}
                />
              </InputContainer>
            </div>
          ))}
        </ContentSection>

        <Button type="submit">{isEdit ? 'Update Course' : 'Create Course'}</Button>
      </form>
    </CourseFormContainer>
  );
};

export default CourseForm;
