import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import config from '../../../components/Config';
import { getGames } from './GameApi'; // Import the Game API

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto; /* Allows scrolling */
`;

const ModalContent = styled.div`
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  margin: 20px auto; /* Adds some space around the modal */
  height: 90vh; /* Adjust to make the content fit within the screen */
  overflow-y: auto; /* Allows vertical scrolling for the modal content */
`;

const FormField = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  background-color: #444;
  color: white;
  border: 1px solid #555;
  border-radius: 6px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  background-color: #444;
  color: white;
  border: 1px solid #555;
  border-radius: 6px;
`;

const CategoryInput = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

const Button = styled.button`
  background-color: #1a1a1a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #555;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ToggleLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c2c2c;
  padding: 10px;
  border-radius: 5px;
  color: white;
`;

const ToggleSwitch = styled.div`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 21px;
      width: 21px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;
    }
  }

  input:checked + span {
    background-color: #5cb85c;
  }

  input:checked + span::before {
    transform: translateX(24px);
  }
`;

const GuideForm = ({ onClose, guide, onSave }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [categories, setCategories] = useState([{ name: '', description: '' }]);
  const [games, setGames] = useState([]);
  const [selectedGameId, setSelectedGameId] = useState('');

  useEffect(() => {
    if (guide) {
      setTitle(guide.title);
      setDescription(guide.description);
      setImage(guide.image);
      setVideoLink(guide.videoLink);
      setCategories(guide.categories || []);
      setSelectedGameId(guide.game ? guide.game._id : '');
    } else {
      // Reset form fields for creating a new guide
      setTitle('');
      setDescription('');
      setImage('');
      setVideoLink('');
      setCategories([{ name: '', description: '' }]);
      setSelectedGameId('');
    }
  }, [guide]);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const gamesData = await getGames();
        setGames(gamesData);
      } catch (err) {
        console.error('Failed to fetch games');
      }
    };
    fetchGames();
  }, []);

  const handleCategoryChange = (index, key, value) => {
    const updatedCategories = categories.map((category, i) =>
      i === index ? { ...category, [key]: value } : category
    );
    setCategories(updatedCategories);
  };

  const handleAddCategory = () => {
    setCategories([...categories, { name: '', description: '' }]);
  };

  const handleGameToggle = (gameId) => {
    setSelectedGameId((prevId) => (prevId === gameId ? '' : gameId)); // Toggle selection
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const guideData = {
      title,
      description,
      image,
      videoLink,
      categories,
      game: selectedGameId,
    };

    const url = guide
      ? `${config.API_ENDPOINT}/guides/${guide._id}/update`
      : `${config.API_ENDPOINT}/guides/guidePost`;

    const method = guide ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(guideData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error saving guide:', errorData);
        alert(`Error: ${errorData.error}`);
        return;
      }

      const savedGuide = await response.json();
      onSave(savedGuide); // Trigger the save callback with the updated guide
      onClose();
    } catch (error) {
      console.error('Error saving guide:', error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h3>{guide ? 'Edit Guide' : 'Add New Guide'}</h3>
        <form onSubmit={handleSubmit}>
          <FormField>
            <Label>Title</Label>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} required />
          </FormField>
          <FormField>
            <Label>Description</Label>
            <TextArea value={description} onChange={(e) => setDescription(e.target.value)} required />
          </FormField>
          <FormField>
            <Label>Image URL</Label>
            <Input value={image} onChange={(e) => setImage(e.target.value)} />
          </FormField>
          <FormField>
            <Label>Video Link</Label>
            <Input value={videoLink} onChange={(e) => setVideoLink(e.target.value)} />
          </FormField>
          <FormField>
            <Label>Categories</Label>
            {categories.map((category, index) => (
              <CategoryInput key={index}>
                <Input
                  placeholder="Category Name"
                  value={category.name}
                  onChange={(e) => handleCategoryChange(index, 'name', e.target.value)}
                />
                <Input
                  placeholder="Category Description"
                  value={category.description}
                  onChange={(e) => handleCategoryChange(index, 'description', e.target.value)}
                />
              </CategoryInput>
            ))}
            <Button type="button" onClick={handleAddCategory}>
              Add Category
            </Button>
          </FormField>

          <FormField>
            <Label>Select Game</Label>
            <ToggleContainer>
              {games.map((game) => (
                <ToggleLabel key={game._id}>
                  {game.name}
                  <ToggleSwitch>
                    <input
                      type="checkbox"
                      checked={selectedGameId === game._id}
                      onChange={() => handleGameToggle(game._id)}
                    />
                    <span></span>
                  </ToggleSwitch>
                </ToggleLabel>
              ))}
            </ToggleContainer>
          </FormField>

          <Button type="submit">{guide ? 'Update Guide' : 'Create Guide'}</Button>
          <Button type="button" onClick={onClose} style={{ marginLeft: '10px' }}>
            Cancel
          </Button>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default GuideForm;
