import React from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure you're using useNavigate
import { getCourses, disableCourse, deleteCourse } from './CourseApi';
import styled from 'styled-components';

const CourseListContainer = styled.div`
  padding: 20px;
  background-color: #1a1a1a;
  color: white;
`;

const CourseItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #333;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const ActionButton = styled.button`
  background-color: ${({ variant }) => (variant === 'delete' ? 'red' : variant === 'disable' ? 'orange' : 'green')};
  color: white;
  padding: 5px 10px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }
`;

const CourseList = () => {
  const [courses, setCourses] = React.useState([]);
  const [error, setError] = React.useState(null);
  const navigate = useNavigate(); // Correct way to handle navigation in React Router v6

  React.useEffect(() => {
    const fetchCourses = async () => {
      try {
        const fetchedCourses = await getCourses();
        setCourses(fetchedCourses);
      } catch (err) {
        setError('Failed to load courses');
      }
    };

    fetchCourses();
  }, []);

  const handleDisable = async (id) => {
    try {
      await disableCourse(id);
      setCourses(courses.map(course => course._id === id ? { ...course, status: 'disabled' } : course));
    } catch (err) {
      setError('Failed to disable course');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteCourse(id);
      setCourses(courses.filter(course => course._id !== id));
    } catch (err) {
      setError('Failed to delete course');
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/courses/edit/${id}`); // Navigating to the edit form
  };

  if (error) return <p>{error}</p>;

  return (
    <CourseListContainer>
      <h2>Manage Courses</h2>
      {courses.map(course => (
        <CourseItem key={course._id}>
          <div>
            <h3>{course.title}</h3>
            <p>{course.game ? course.game.name : 'Unknown Game'}</p> {/* Safely accessing game.name */}
            <p>Status: {course.status}</p>
          </div>
          <div>
            <ActionButton variant="edit" onClick={() => handleEdit(course._id)}>Edit</ActionButton> {/* Triggering navigation */}
            {course.status === 'active' && (
              <ActionButton variant="disable" onClick={() => handleDisable(course._id)}>Disable</ActionButton>
            )}
            <ActionButton variant="delete" onClick={() => handleDelete(course._id)}>Delete</ActionButton>
          </div>
        </CourseItem>
      ))}
    </CourseListContainer>
  );
};

export default CourseList;

