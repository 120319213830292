import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GuideList from './GuideList';
import GuideForm from './GuideForm';
import config from '../../../components/Config';

// Styled Button Component
const CreateButton = styled.button`
  padding: 12px 20px;
  margin: 20px 0; /* Add margin to top and bottom */
  background-color: #5cb85c; /* Neon green background */
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #4cae4c; /* Darker green on hover */
    transform: scale(1.05);
  }
`;

const GuideManager = () => {
  const [guides, setGuides] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedGuide, setSelectedGuide] = useState(null);

  // Function to fetch the guide data
  const fetchGuides = async () => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/guides/guideGet`);
      const data = await response.json();
      setGuides(data);
    } catch (error) {
      console.error('Failed to fetch guides:', error);
    }
  };

  useEffect(() => {
    fetchGuides();
  }, []);

  const handleToggleStatus = async (guideId) => {
    try {
      const guide = guides.find((g) => g._id === guideId);
      const updatedStatus = guide.status === 'active' ? 'disabled' : 'active';
      
      const response = await fetch(`${config.API_ENDPOINT}/guides/${guideId}/toggleStatus`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: updatedStatus }),
      });

      if (!response.ok) {
        throw new Error('Failed to update guide status');
      }

      // Update the guide status locally after a successful API response
      setGuides((prevGuides) =>
        prevGuides.map((g) =>
          g._id === guideId ? { ...g, status: updatedStatus } : g
        )
      );
    } catch (error) {
      console.error('Error toggling guide status:', error);
    }
  };

  const handleEditGuide = (guide) => {
    setSelectedGuide(guide);
    setIsEditing(true); // Open the edit modal
  };

  const handleDeleteGuide = async (guideId) => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/guides/${guideId}/delete`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete guide');
      }

      // Remove the guide from the list after deletion
      setGuides((prevGuides) => prevGuides.filter((g) => g._id !== guideId));
    } catch (error) {
      console.error('Error deleting guide:', error);
    }
  };

  const handleCloseModal = () => {
    setIsEditing(false);
    setSelectedGuide(null); // Clear the selected guide
    fetchGuides(); // Re-fetch guides when the modal is closed
  };

  const handleUpdateGuide = (updatedGuide) => {
    setGuides((prevGuides) =>
      prevGuides.map((g) => (g._id === updatedGuide._id ? updatedGuide : g))
    );
    handleCloseModal();
  };

  const handleCreateGuide = () => {
    setSelectedGuide(null); // Clear selected guide to indicate a new guide is being created
    setIsEditing(true);
  };

  const handleSaveNewGuide = (newGuide) => {
    setGuides((prevGuides) => [...prevGuides, newGuide]);
    handleCloseModal();
  };

  return (
    <div>
      <CreateButton onClick={handleCreateGuide}>Create New Guide</CreateButton>
      <GuideList
        guides={guides}
        onEdit={handleEditGuide}
        onDelete={handleDeleteGuide}
        onToggleStatus={handleToggleStatus}
      />
      {isEditing && (
        <GuideForm
          guide={selectedGuide} // If null, GuideForm knows it's creating a new guide
          onClose={handleCloseModal}
          onSave={selectedGuide ? handleUpdateGuide : handleSaveNewGuide} // Different handlers for edit vs. create
        />
      )}
    </div>
  );
};

export default GuideManager;

