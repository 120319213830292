import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import config from '../components/Config.js';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #333;
  color: white;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Nav = styled.nav`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const NavList = styled(motion.ul)`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled(motion.li)`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: white; /* Default color */
    font-size: 1.1rem;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    display: flex;
    align-items: center;
    padding-bottom: 0.2rem;
    border-bottom: 2px solid transparent; /* Hidden underline */

    &:hover {
      color: white; /* Keep hover color as white */
    }

    &.active-link {
      font-weight: bold;
      color: white; /* Bold white color for active link */
      border-bottom: 2px solid white; /* White underline */
    }
  }
`;

const DrawerButton = styled(GiHamburgerMenu)`
  display: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const CloseButton = styled(AiOutlineClose)`
  color: white;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const Drawer = styled(motion.div)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;
  padding-left: 1rem;
  z-index: 1000;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const DrawerNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const DrawerNavItem = styled.li`
  margin: 1rem 0;

  a {
    text-decoration: none;
    color: white; /* Default color */
    font-size: 1rem;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    display: inline-block;
    width: 100%;
    padding-bottom: 0.2rem;
    border-bottom: 2px solid transparent; /* Hidden underline */

    &:hover {
      color: white; /* Keep hover color as white */
    }

    &.active-link {
      font-weight: bold;
      color: white; /* Bold white color for active link */
      border-bottom: 2px solid white; /* White underline */
    }
  }
`;

const Header = (Data) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await fetch(`${config.API_ENDPOINT}/auth/logout`, {
        method: 'GET',
        credentials: 'include',
      });
      Data.loginStateUpdate(false);
      navigate('/auth');
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        {isMobile && <DrawerButton onClick={() => setIsDrawerOpen(!isDrawerOpen)} />}
        <Logo>ProAcademy</Logo>
      </LogoContainer>
      <Nav>
        {!isMobile && (
          <NavList 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <NavItem whileHover={{ scale: 1.1 }}>
              <NavLink to="/" className={({ isActive }) => isActive ? "active-link" : ""}>
                Home
              </NavLink>
            </NavItem>
            <NavItem whileHover={{ scale: 1.1 }}>
              <NavLink to="/coaches" className={({ isActive }) => isActive ? "active-link" : ""}>
                Coaches
              </NavLink>
            </NavItem>            
            <NavItem whileHover={{ scale: 1.1 }}>
              <NavLink to="/about" className={({ isActive }) => isActive ? "active-link" : ""}>
                About Us
              </NavLink>
            </NavItem>
            <NavItem whileHover={{ scale: 1.1 }}>
              <NavLink to="/user-dashboard" className={({ isActive }) => isActive ? "active-link" : ""}>
                Dashboard
              </NavLink>
            </NavItem>
            <NavItem whileHover={{ scale: 1.1 }}>
              <NavLink to="/" onClick={handleLogout}>
                Logout
              </NavLink>
            </NavItem>
          </NavList>
        )}
        {isDrawerOpen && (
          <Drawer
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 0.3 }}
          >
            <CloseButton onClick={() => setIsDrawerOpen(false)} />
            <DrawerNavList>
              <DrawerNavItem onClick={() => setIsDrawerOpen(false)}>
                <NavLink to="/" className={({ isActive }) => isActive ? "active-link" : ""}>
                  Home
                </NavLink>
              </DrawerNavItem>
              <DrawerNavItem onClick={() => setIsDrawerOpen(false)}>
                <NavLink to="/coaches" className={({ isActive }) => isActive ? "active-link" : ""}>
                  Coaches
                </NavLink>
              </DrawerNavItem>
              <DrawerNavItem onClick={() => setIsDrawerOpen(false)}>
                <NavLink to="/about" className={({ isActive }) => isActive ? "active-link" : ""}>
                  About Us
                </NavLink>
              </DrawerNavItem>
              <DrawerNavItem onClick={() => setIsDrawerOpen(false)}>
                <NavLink to="/user-dashboard" className={({ isActive }) => isActive ? "active-link" : ""}>
                  Dashboard
                </NavLink>
              </DrawerNavItem>
              <DrawerNavItem onClick={() => setIsDrawerOpen(false)}>
                <NavLink to="/" onClick={handleLogout}>
                  Logout
                </NavLink>
              </DrawerNavItem>
            </DrawerNavList>
          </Drawer>
        )}
      </Nav>
    </HeaderContainer>
  );
};

export default Header;

