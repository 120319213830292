import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import config from '../../../components/Config'; // Adjust the path based on your project structure

const CoachCardContainer = styled.div`
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  width: 300px;
  text-align: center;
  color: #ffffff;
`;

const CoachName = styled.h2`
  margin-bottom: 10px;
  color: #ffffff;
`;

const CoachBio = styled.p`
  font-size: 14px;
  color: #cccccc;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  margin: 5px;
  border: none;
  background-color: ${(props) =>
    props.red ? '#d9534f' : '#5cb85c'};
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s;

  &:hover {
    opacity: 0.9;
  }
`;

const EditButton = styled(ActionButton)`
  background-color: #f0ad4e; /* Orange color for the edit button */
  margin-top: 10px;

  &:hover {
    background-color: #ec971f;
  }
`;

const updateCoachStatus = async (coachId, action) => {
  try {
    const response = await fetch(`${config.API_ENDPOINT}/admin/coach/${action}/${coachId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to ${action} coach`);
    }

    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

const CoachCard = ({ coach, setCoaches }) => {
  const navigate = useNavigate();

  const handleToggleApproval = async () => {
    const action = coach.isApproved ? 'disapprove' : 'approve';
    const success = await updateCoachStatus(coach._id, action);
    if (success) {
      setCoaches((prevCoaches) =>
        prevCoaches.map((c) =>
          c._id === coach._id ? { ...c, isApproved: !c.isApproved, status: c.isApproved ? 'inactive' : 'active' } : c
        )
      );
    }
  };

  const handleToggleEnable = async () => {
    const action = coach.status === 'active' ? 'disable' : 'enable';
    const success = await updateCoachStatus(coach._id, action);
    if (success) {
      setCoaches((prevCoaches) =>
        prevCoaches.map((c) =>
          c._id === coach._id ? { ...c, status: c.status === 'active' ? 'disabled' : 'active' } : c
        )
      );
    }
  };

  const handleEdit = () => {
    navigate(`/admin/coach/edit/${coach._id}`); // Navigate to the edit coach page
  };

  return (
    <CoachCardContainer>
      <CoachName>{coach.user.name}</CoachName>
      <CoachBio>{coach.bio}</CoachBio>
      <ActionButton onClick={handleToggleApproval} red={coach.isApproved}>
        {coach.isApproved ? 'Disapprove' : 'Approve'}
      </ActionButton>
      <ActionButton onClick={handleToggleEnable} red={coach.status === 'active'}>
        {coach.status === 'active' ? 'Disable' : 'Enable'}
      </ActionButton>
      <EditButton onClick={handleEdit}>Edit</EditButton>
    </CoachCardContainer>
  );
};

export default CoachCard;

