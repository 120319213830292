import config from '../../../components/Config'; // Adjust the path based on your project structure

// Function to fetch all games
export const getGames = async () => {
  try {
    const response = await fetch(`${config.API_ENDPOINT}/admin/games`);
    if (!response.ok) {
      throw new Error('Failed to fetch games');
    }
    const games = await response.json();
    return games;
  } catch (error) {
    console.error('Error fetching games:', error.message);
    return []; // Return an empty array in case of error to avoid breaking the UI
  }
};
