import React from 'react';
import styled from 'styled-components';

const GuideTable = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #333;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #2a2a2a;
  }
`;

const TableData = styled.td`
  padding: 10px;
`;

const ActionButton = styled.button`
  background-color: #1a1a1a;
  color: white;
  padding: 8px 12px;
  margin-right: 8px;
  border: 1px solid #555;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #333;
  }
`;

const DeleteButton = styled(ActionButton)`
  background-color: darkred;
  &:hover {
    background-color: red;
  }
`;

const ToggleStatusButton = styled(ActionButton)`
  background-color: ${({ status }) => (status === 'active' ? 'darkorange' : 'darkgreen')};
  &:hover {
    background-color: ${({ status }) => (status === 'active' ? 'orange' : 'green')};
  }
`;

const GuideList = ({ guides, onEdit, onDelete, onToggleStatus }) => {
  return (
    <GuideTable>
      <thead>
        <tr>
          <TableHeader>Title</TableHeader>
          <TableHeader>Categories</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Actions</TableHeader>
        </tr>
      </thead>
      <tbody>
        {guides.map((guide) => (
          <TableRow key={guide._id}>
            <TableData>{guide.title}</TableData>
            <TableData>{guide.categories.map((category) => category.name).join(', ')}</TableData>
            <TableData>{guide.status}</TableData>
            <TableData>
              <ActionButton onClick={() => onEdit(guide)}>Edit</ActionButton>
              <DeleteButton onClick={() => onDelete(guide._id)}>Delete</DeleteButton>
              <ToggleStatusButton
                status={guide.status}
                onClick={() => onToggleStatus(guide._id)}
              >
                {guide.status === 'active' ? 'Disable' : 'Enable'}
              </ToggleStatusButton>
            </TableData>
          </TableRow>
        ))}
      </tbody>
    </GuideTable>
  );
};

export default GuideList;

