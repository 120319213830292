import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import config from '../../components/Config.js';

const AdminUserList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedAccess, setSelectedAccess] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (statusMessage) {
      const timer = setTimeout(() => {
        setStatusMessage('');
      }, 3000); // Message will disappear after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [statusMessage]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin-user/users`);
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setStatusMessage('Error fetching users.');
    }
  };

  const filterUsers = useCallback(() => {
    const filtered = users.filter((user) => {
      return (
        (user.name?.toLowerCase().includes(searchTerm.toLowerCase()) || user.email?.toLowerCase().includes(searchTerm.toLowerCase()) || user.mobile?.includes(searchTerm)) &&
        (selectedRole ? user.role === selectedRole : true) &&
        (selectedAccess ? (selectedAccess === 'granted' ? user.accessGranted : !user.accessGranted) : true)
      );
    });
    setFilteredUsers(filtered);
  }, [users, searchTerm, selectedRole, selectedAccess]);

  useEffect(() => {
    filterUsers();
  }, [filterUsers]);

  const handleToggleAccess = async (id) => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin-user/users/toggle-access/${id}`, {
        method: 'PUT',
      });

      if (response.ok) {
        setStatusMessage('User access updated successfully!');
        fetchUsers(); // Refresh the list to show the updated access status
      } else {
        setStatusMessage('Error updating user access.');
      }
    } catch (error) {
      setStatusMessage('Error updating user access.');
    }
  };

  return (
    <AdminUserListContainer>
      <h2>Manage Users</h2>
      {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}

      {/* Search and Filter Controls */}
      <SearchFilterContainer>
        <input
          type="text"
          placeholder="Search by name, email, or mobile..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
          <option value="">All Roles</option>
          <option value="gamer">Gamer</option>
          <option value="coach">Coach</option>
          <option value="admin">Admin</option>
        </select>
        <select value={selectedAccess} onChange={(e) => setSelectedAccess(e.target.value)}>
          <option value="">All Access Statuses</option>
          <option value="granted">Access Granted</option>
          <option value="revoked">Access Revoked</option>
        </select>
      </SearchFilterContainer>

      <UserTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email / Mobile</th> {/* Updated the heading here */}
            <th>Role</th>
            <th>Access</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.email || user.mobile}</td> {/* Display either email or mobile */}
              <td>{user.role}</td>
              <td>{user.accessGranted ? 'Granted' : 'Revoked'}</td>
              <td>
                <ActionButton onClick={() => handleToggleAccess(user._id)}>
                  {user.accessGranted ? 'Revoke Access' : 'Grant Access'}
                </ActionButton>
              </td>
            </tr>
          ))}
        </tbody>
      </UserTable>
    </AdminUserListContainer>
  );
};

// Styled components

const AdminUserListContainer = styled.div`
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
`;

const SearchFilterContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  input,
  select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #333;
    background-color: #333;
    color: white;
  }
`;

const UserTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 12px 15px;
    border: 1px solid #333;
  }

  th {
    background-color: #333;
  }

  td {
    background-color: #444;
  }
`;

const ActionButton = styled.button`
  padding: 6px 12px;
  margin-right: 10px;
  background-color: ${(props) => (props.delete ? '#e74c3c' : '#3498db')};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.delete ? '#c0392b' : '#2980b9')};
  }
`;

const StatusMessage = styled.div`
  margin-bottom: 20px;
  color: #f48024;
  font-size: 16px;
`;

export default AdminUserList;
