import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './Layout';
import Main from './Main';
import "./App.css"

const App = () => {
  const [loginState, loginStateUpdate] = useState(false);

  return (
    <Layout>
      <Router>
        <Main loginState={loginState} loginStateUpdate={loginStateUpdate} />
      </Router>
    </Layout>
  );
}

export default App;


