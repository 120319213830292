import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import config from '../../components/Config.js';

const AdminGameList = () => {
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    fetchGames();
  }, []);

  const fetchGames = async () => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin/games`);
      const data = await response.json();
      setGames(data);
    } catch (error) {
      setStatusMessage('Error fetching games.');
    }
  };

  const filterGames = useCallback(() => {
    const filtered = games.filter((game) => {
      return (
        game.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedGenre ? game.genre === selectedGenre : true) &&
        (selectedPlatform ? game.platform.includes(selectedPlatform) : true) &&
        (selectedStatus ? game.status === selectedStatus : true)
      );
    });
    setFilteredGames(filtered);
  }, [games, searchTerm, selectedGenre, selectedPlatform, selectedStatus]);

  useEffect(() => {
    filterGames();
  }, [filterGames]);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin/games/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setStatusMessage('Game deleted successfully!');
        fetchGames(); // Refresh the list
      } else {
        setStatusMessage('Error deleting the game.');
      }
    } catch (error) {
      setStatusMessage('Error deleting the game.');
    }
  };

  const handleEdit = (id) => {
    window.location.href = `/admin/edit-game/${id}`;
  };

  const handleToggleStatus = async (id) => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin/games/toggle-status/${id}`, {
        method: 'PUT',
      });

      if (response.ok) {
        setStatusMessage('Game status updated successfully!');
        fetchGames(); // Refresh the list to show the updated status
      } else {
        setStatusMessage('Error updating game status.');
      }
    } catch (error) {
      setStatusMessage('Error updating game status.');
    }
  };

  return (
    <AdminGameListContainer>
      <h2>Manage Games</h2>
      {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}

      {/* Search and Filter Controls */}
      <SearchFilterContainer>
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select value={selectedGenre} onChange={(e) => setSelectedGenre(e.target.value)}>
          <option value="">All Genres</option>
          <option value="Action">Action</option>
          <option value="RPG">RPG</option>
          <option value="FPS">FPS</option>
          {/* Add more genre options here */}
        </select>
        <select value={selectedPlatform} onChange={(e) => setSelectedPlatform(e.target.value)}>
          <option value="">All Platforms</option>
          <option value="PC">PC</option>
          <option value="Console">Console</option>
          <option value="Mobile">Mobile</option>
        </select>
        <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
          <option value="">All Statuses</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </SearchFilterContainer>

      <GameTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Genre</th>
            <th>Platform</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredGames.map((game) => (
            <tr key={game._id}>
              <td>{game.name}</td>
              <td>{game.genre}</td>
              <td>{game.platform.join(', ')}</td>
              <td>{game.status}</td>
              <td>
                <ActionButton onClick={() => handleEdit(game._id)}>Edit</ActionButton>
                <ActionButton onClick={() => handleDelete(game._id)} delete>
                  Delete
                </ActionButton>
                <ActionButton onClick={() => handleToggleStatus(game._id)}>
                  {game.status === 'active' ? 'Disable' : 'Enable'}
                </ActionButton>
              </td>
            </tr>
          ))}
        </tbody>
      </GameTable>
    </AdminGameListContainer>
  );
};

// Styled components

const AdminGameListContainer = styled.div`
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
`;

const SearchFilterContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  input,
  select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #333;
    background-color: #333;
    color: white;
  }
`;

const GameTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 12px 15px;
    border: 1px solid #333;
  }

  th {
    background-color: #333;
  }

  td {
    background-color: #444;
  }
`;

const ActionButton = styled.button`
  padding: 6px 12px;
  margin-right: 10px;
  background-color: ${(props) => (props.delete ? '#e74c3c' : props.toggle ? '#f48024' : '#3498db')};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.delete ? '#c0392b' : props.toggle ? '#e1701c' : '#2980b9')};
  }
`;

const StatusMessage = styled.div`
  margin-bottom: 20px;
  color: #f48024;
  font-size: 16px;
`;

export default AdminGameList;

