import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const AdminHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #444;
  color: white;
`;

const AdminNav = styled.nav`
  display: flex;
  align-items: center; /* Ensures all items are vertically aligned */
  gap: 2rem;
  margin-left: auto;
`;

const AdminNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  padding: 0.5rem 0; /* Remove padding that could affect vertical alignment */
  position: relative;

  &.active {
    font-weight: bold;
    color: #f48024;
  }

  &:hover {
    color: #f48024;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center; /* Ensures dropdown items are vertically aligned */
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #555;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;

  a {
    display: block;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
  }

  a:hover {
    background-color: #666;
  }
`;

const AdminHeader = () => {
  const [isGamesDropdownOpen, setIsGamesDropdownOpen] = useState(false);
  const [isCoachesDropdownOpen, setIsCoachesDropdownOpen] = useState(false);
  const [isCoursesDropdownOpen, setIsCoursesDropdownOpen] = useState(false);
  const [isUIDropdownOpen, setIsUIDropdownOpen] = useState(false);

  return (
    <AdminHeaderContainer>
      <div>Admin Panel</div>
      <AdminNav>
        {/* UI Group */}
        <DropdownContainer
          onMouseEnter={() => setIsUIDropdownOpen(true)}
          onMouseLeave={() => setIsUIDropdownOpen(false)}
        >
          <AdminNavLink to="#">UI</AdminNavLink>
          <DropdownMenu isOpen={isUIDropdownOpen}>
            <NavLink to="/admin/admin-home">Home</NavLink>
            <NavLink to="/admin/admin-coaches">Coaches</NavLink>
            <NavLink to="/admin/admin-about">About</NavLink>
          </DropdownMenu>
        </DropdownContainer>

        {/* Games Group */}
        <DropdownContainer
          onMouseEnter={() => setIsGamesDropdownOpen(true)}
          onMouseLeave={() => setIsGamesDropdownOpen(false)}
        >
          <AdminNavLink to="#">Games</AdminNavLink>
          <DropdownMenu isOpen={isGamesDropdownOpen}>
            <NavLink to="/admin/create-game">Create Game</NavLink>
            <NavLink to="/admin/manage-games">Manage Games</NavLink>
          </DropdownMenu>
        </DropdownContainer>

        {/* Coaches Group */}
        <DropdownContainer
          onMouseEnter={() => setIsCoachesDropdownOpen(true)}
          onMouseLeave={() => setIsCoachesDropdownOpen(false)}
        >
          <AdminNavLink to="#">Coaches</AdminNavLink>
          <DropdownMenu isOpen={isCoachesDropdownOpen}>
            <NavLink to="/admin/manage-coaches">Manage Coaches</NavLink>
          </DropdownMenu>
        </DropdownContainer>

        {/* Courses Group */}
        <DropdownContainer
          onMouseEnter={() => setIsCoursesDropdownOpen(true)}
          onMouseLeave={() => setIsCoursesDropdownOpen(false)}
        >
          <AdminNavLink to="#">Courses</AdminNavLink>
          <DropdownMenu isOpen={isCoursesDropdownOpen}>
            <NavLink to="/admin/courses">Course List</NavLink>
            <NavLink to="/admin/courses/new">Create Course</NavLink>
          </DropdownMenu>
        </DropdownContainer>

        {/* Guides Route */}
        <AdminNavLink to="/admin/guides">Guides</AdminNavLink>
        <AdminNavLink to="/admin/user-management">Users</AdminNavLink>
      </AdminNav>
    </AdminHeaderContainer>
  );
};

export default AdminHeader;
