import config from '../../../components/Config';

export const getCourses = async (filters = {}) => {
  const query = new URLSearchParams(filters).toString();
  try {
    const response = await fetch(`${config.API_ENDPOINT}/courses?${query}`);
    if (!response.ok) throw new Error('Failed to fetch courses');
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getCourseById = async (id) => {
  try {
    const response = await fetch(`${config.API_ENDPOINT}/courses/${id}`);
    if (!response.ok) throw new Error('Failed to fetch course details');
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const createCourse = async (courseData) => {
  try {
    const response = await fetch(`${config.API_ENDPOINT}/courses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(courseData),
    });
    if (!response.ok) throw new Error('Failed to create course');
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const updateCourse = async (id, courseData) => {
  try {
    const response = await fetch(`${config.API_ENDPOINT}/courses/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(courseData),
    });
    if (!response.ok) throw new Error('Failed to update course');
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const disableCourse = async (id) => {
  try {
    const response = await fetch(`${config.API_ENDPOINT}/courses/${id}/disable`, {
      method: 'PATCH',
    });
    if (!response.ok) throw new Error('Failed to disable course');
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteCourse = async (id) => {
  try {
    const response = await fetch(`${config.API_ENDPOINT}/courses/${id}`, {
      method: 'DELETE',
    });
    if (!response.ok) throw new Error('Failed to delete course');
    return await response.json();
  } catch (error) {
    throw error;
  }
};
