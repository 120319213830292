import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import config from '../../components/Config.js';

const AdminContainer = styled.div`
  padding: 20px;
  background-color: #1a1a1a;
  color: #f0f0f0;
  font-family: 'Arial', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: none;
  background-color: #333;
  color: #f0f0f0;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: none;
  background-color: #333;
  color: #f0f0f0;
  resize: vertical;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #f48024;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e1701c;
  }
  margin-right: 10px;
`;

const PreviewContainer = styled.div`
  margin-top: 40px;
  padding: 20px;
  background-color: #2b2b2b;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #333;
  padding: 40px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
`;

const HeroText = styled.div`
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

const HeroImage = styled.div`
  width: 40%;
  height: 300px;
  background-color: #ddd;
  border-radius: 8px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const SearchBar = styled.div`
  margin-bottom: 20px;
  text-align: center;

  input {
    padding: 10px;
    border: none;
    border-radius: 4px;
    width: 100%;
    max-width: 400px;
    background: #333;
    color: #f0f0f0;
  }
`;

const AdminHeroSearchEdit = () => {
  const [heroTitle, setHeroTitle] = useState('');
  const [heroDescription, setHeroDescription] = useState('');
  const [heroImage, setHeroImage] = useState(null); // Change from URL to file upload
  const [heroImagePreview, setHeroImagePreview] = useState(''); // Preview the uploaded image
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    // Fetch existing data from the server
    fetch(`${config.API_ENDPOINT}/admin/ui/coach/get-hero-search-config`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setHeroTitle(data.heroTitle || '');
        setHeroDescription(data.heroDescription || '');
        setHeroImagePreview(data.heroImage || ''); // Set the image preview
        setSearchPlaceholder(data.searchPlaceholder || '');
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setHeroImage(file);
      setHeroImagePreview(URL.createObjectURL(file)); // Preview the uploaded image
    }
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append('heroTitle', heroTitle);
    formData.append('heroDescription', heroDescription);
    formData.append('searchPlaceholder', searchPlaceholder);
    if (heroImage) {
      formData.append('heroImage', heroImage); // Add the image file to the form data
    }

    fetch(`${config.API_ENDPOINT}/admin/ui/coach/update-hero-search-config`, {
      method: 'POST',
      credentials: 'include',
      body: formData, // Send the form data
    })
      .then((response) => response.json())
      .then((data) => {
        alert('Hero section and search bar updated successfully!');
      })
      .catch((error) => console.error('Error saving data:', error));
  };

  return (
    <AdminContainer>
      <h2>Edit Hero Section and Search Bar</h2>

      <FormGroup>
        <Label htmlFor="heroTitle">Hero Title</Label>
        <Input
          type="text"
          id="heroTitle"
          value={heroTitle}
          onChange={(e) => setHeroTitle(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="heroDescription">Hero Description</Label>
        <TextArea
          id="heroDescription"
          rows="4"
          value={heroDescription}
          onChange={(e) => setHeroDescription(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="heroImage">Upload Hero Background Image</Label>
        <Input
          type="file"
          id="heroImage"
          accept="image/*"
          onChange={handleImageChange}
        />
        {heroImagePreview && (
          <img
            src={heroImagePreview}
            alt="Hero Preview"
            style={{ width: '100%', marginTop: '10px', borderRadius: '4px' }}
          />
        )}
      </FormGroup>

      <FormGroup>
        <Label htmlFor="searchPlaceholder">Search Bar Placeholder</Label>
        <Input
          type="text"
          id="searchPlaceholder"
          value={searchPlaceholder}
          onChange={(e) => setSearchPlaceholder(e.target.value)}
        />
      </FormGroup>

      <Button onClick={handleSave}>Save Changes</Button>
      <Button onClick={() => setShowPreview(!showPreview)}>
        {showPreview ? 'Hide Preview' : 'Show Preview'}
      </Button>

      {showPreview && (
        <PreviewContainer>
          <h3>Preview</h3>
          <HeroSection>
            <HeroText>
              <h1>{heroTitle}</h1>
              <p>{heroDescription}</p>
            </HeroText>
            <HeroImage imageUrl={heroImagePreview} />
          </HeroSection>
          <SearchBar>
            <input type="text" placeholder={searchPlaceholder} />
          </SearchBar>
        </PreviewContainer>
      )}
    </AdminContainer>
  );
};

export default AdminHeroSearchEdit;
