import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  width: 100%;
  height: 60px;
  background-color: #2b2b2b;
  color: #f0f0f0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  margin-top: auto;
`;

const Footer = () => {
  return (
    <FooterContainer>
      &copy; {new Date().getFullYear()} proacademy.gg
    </FooterContainer>
  );
};

export default Footer;

