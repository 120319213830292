import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import config from '../../components/Config.js';

const AdminHomeContainer = styled.div`
  padding: 40px;
  background-color: #121212;
  color: #f0f0f0;
  font-family: 'Roboto', sans-serif;
`;

const FormSection = styled.div`
  margin-bottom: 40px;
  padding: 20px;
  background-color: #1f1f1f;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #f48024;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #b0b0b0;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #444;
  background-color: #2b2b2b;
  color: white;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #444;
  background-color: #2b2b2b;
  color: white;
  box-sizing: border-box;
`;

const FileInput = styled.input`
  margin-bottom: 20px;
  color: #b0b0b0;
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: #f48024;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: #e1701c;
  }
  margin-top: 10px;
`;

const AdminHome = () => {
  const [content, setContent] = useState({
    heroSection: {
      title: '',
      subtitle: '',
      buttonText: '',
      buttonLink: '',
    },
    howItWorks: [],
    popularGames: [],
    testimonials: [],
  });

  const [heroImage, setHeroImage] = useState(null);
  const [stepImages, setStepImages] = useState([]);
  const [gameImages, setGameImages] = useState([]);

  useEffect(() => {
    fetch(`${config.API_ENDPOINT}/admin/ui/homepage-content`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setContent({
            heroSection: data.heroSection || {
              title: '',
              subtitle: '',
              buttonText: '',
              buttonLink: '',
            },
            howItWorks: data.howItWorks || [],
            popularGames: data.popularGames || [],
            testimonials: data.testimonials || [],
          });
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleInputChange = (e, section, field, index) => {
    if (index !== undefined) {
      const updatedSection = [...(content[section] || [])];
      updatedSection[index][field] = e.target.value;
      setContent({
        ...content,
        [section]: updatedSection,
      });
    } else {
      setContent({
        ...content,
        [section]: {
          ...(content[section] || {}),
          [field]: e.target.value,
        },
      });
    }
  };

  const handleHeroImageChange = (e) => {
    setHeroImage(e.target.files[0]);
  };

  const handleStepImageChange = (e, index) => {
    const newStepImages = [...stepImages];
    newStepImages[index] = e.target.files[0];
    setStepImages(newStepImages);
  };

  const handleGameImageChange = (e, index) => {
    const newGameImages = [...gameImages];
    newGameImages[index] = e.target.files[0];
    setGameImages(newGameImages);
  };

  const handleAddStep = () => {
    setContent({
      ...content,
      howItWorks: [
        ...(content.howItWorks || []),
        { stepTitle: '', stepDescription: '' },
      ],
    });
  };

  const handleAddGame = () => {
    setContent({
      ...content,
      popularGames: [
        ...(content.popularGames || []),
        { gameTitle: '' },
      ],
    });
  };

  const handleAddTestimonial = () => {
    setContent({
      ...content,
      testimonials: [
        ...(content.testimonials || []),
        { author: '', text: '' },
      ],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('heroTitle', content.heroSection.title);
    formData.append('heroSubtitle', content.heroSection.subtitle);
    formData.append('heroButtonText', content.heroSection.buttonText);
    formData.append('heroButtonLink', content.heroSection.buttonLink);
    formData.append('heroImage', heroImage);

    content.howItWorks.forEach((step, index) => {
      formData.append(`howItWorks[${index}][stepTitle]`, step.stepTitle);
      formData.append(`howItWorks[${index}][stepDescription]`, step.stepDescription);
      formData.append(`howItWorks[${index}][stepImage]`, stepImages[index]);
    });

    content.popularGames.forEach((game, index) => {
      formData.append(`popularGames[${index}][gameTitle]`, game.gameTitle);
      formData.append(`popularGames[${index}][gameImage]`, gameImages[index]);
    });

    console.log(formData)

    try {
      const response = await fetch(`${config.API_ENDPOINT}/admin/ui/homepage-content`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to save content');
      }

      const data = await response.json();
      console.log('Server response:', data);
      alert('Content updated successfully!');
    } catch (error) {
      console.error('Error updating content:', error);
      alert('Failed to update content.');
    }
  };

  return (
    <AdminHomeContainer>
      <h1>Update Homepage Content</h1>

      {/* Hero Section */}
      <FormSection>
        <SectionHeader>
          <SectionTitle>Hero Section</SectionTitle>
        </SectionHeader>
        <Label>Title</Label>
        <Input
          value={content.heroSection.title}
          onChange={(e) => handleInputChange(e, 'heroSection', 'title')}
        />
        <Label>Subtitle</Label>
        <Input
          value={content.heroSection.subtitle}
          onChange={(e) => handleInputChange(e, 'heroSection', 'subtitle')}
        />
        <Label>Button Text</Label>
        <Input
          value={content.heroSection.buttonText}
          onChange={(e) => handleInputChange(e, 'heroSection', 'buttonText')}
        />
        <Label>Button Link</Label>
        <Input
          value={content.heroSection.buttonLink}
          onChange={(e) => handleInputChange(e, 'heroSection', 'buttonLink')}
        />
        <Label>Hero Image</Label>
        <FileInput type="file" onChange={handleHeroImageChange} accept="image/*" />

        {/* How It Works Section */}
        <SectionHeader>
          <SectionTitle>How It Works</SectionTitle>
        </SectionHeader>
        {content.howItWorks.map((step, index) => (
          <div key={index}>
            <Label>Step Title</Label>
            <Input
              value={step.stepTitle}
              onChange={(e) => handleInputChange(e, 'howItWorks', 'stepTitle', index)}
            />
            <Label>Step Description</Label>
            <TextArea
              value={step.stepDescription}
              onChange={(e) => handleInputChange(e, 'howItWorks', 'stepDescription', index)}
            />
            <Label>Step Image</Label>
            <FileInput type="file" onChange={(e) => handleStepImageChange(e, index)} accept="image/*" />
          </div>
        ))}
        <Button onClick={handleAddStep}>Add Step</Button>

        {/* Popular Games Section */}
        <SectionHeader>
          <SectionTitle>Popular Games</SectionTitle>
        </SectionHeader>
        {content.popularGames.map((game, index) => (
          <div key={index}>
            <Label>Game Title</Label>
            <Input
              value={game.gameTitle}
              onChange={(e) => handleInputChange(e, 'popularGames', 'gameTitle', index)}
            />
            <Label>Game Image</Label>
            <FileInput type="file" onChange={(e) => handleGameImageChange(e, index)} accept="image/*" />
          </div>
        ))}
        <Button onClick={handleAddGame}>Add Game</Button>

        {/* Testimonials Section */}
        <SectionHeader>
          <SectionTitle>What Our Users Have to Say</SectionTitle>
        </SectionHeader>
        {content.testimonials.map((testimonial, index) => (
          <div key={index}>
            <Label>Author</Label>
            <Input
              value={testimonial.author}
              onChange={(e) => handleInputChange(e, 'testimonials', 'author', index)}
            />
            <Label>Text</Label>
            <TextArea
              value={testimonial.text}
              onChange={(e) => handleInputChange(e, 'testimonials', 'text', index)}
            />
          </div>
        ))}
        <Button onClick={handleAddTestimonial}>Add Testimonial</Button>
      </FormSection>

      <Button onClick={handleSubmit}>Save Changes</Button>
    </AdminHomeContainer>
  );
};

export default AdminHome;

